<template>
  <div class="article">
    <h1 class="article__title">{{ title }}</h1>
    <div class="article__content">{{ content }}</div>
  </div>
</template>

<script>
import { getUrlArgObject }  from "@/utils/tools";
import UserInfo from "@/api/user";
export default {
  data() {
    return {
      title: '',
      content: ""
    }
  },
  created() {
    const url_param = getUrlArgObject();
    if (url_param.hasOwnProperty('task_id')) {
      this.get_detail(url_param.task_id);
    } else {
      this.$toast("task_id获取失败!");
    }
  },
  methods: {
    async get_detail(id) {
      const res = await UserInfo.getUserDetail(id);
      if (res.code == 200) {
        const { title, content } = res.data;
        this.title = title;
        this.content = content;
      } else {
        this.$toast(res.msg);
      }
    }
  }
};
</script>

<style lang="scss">
.article {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &__content {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    text-align: justify;

    & > * + * {
      margin-top: 10px;
    }
  }
}
</style>
